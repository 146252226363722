<template>
    <section>      
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">JSON builder</p>
        </header>
        
        
        <section class="modal-card-body" style="padding-bottom: 350px">          
          
          <b-field class="columns is-multiline">
            <div class="column is-6">
              
              <b-field label="Title">
                <b-input type="text" :value="frmData.title" v-model="frmData.title" placeholder="title"></b-input>
              </b-field>

              <b-field label="Note">
                <b-input maxlength="20000" type="textarea" :value="frmData.note" v-model="frmData.note" placeholder="note..."></b-input>
              </b-field>
              
              <b-field label="Periode">
                <b-select placeholder="Select Periode" v-model="selectedPeriod" @input="selectPeriodChanged">
                  <option
                    v-for="period in frmData.periodData"
                    :value="period"
                    :key="period.company_periods_id">
                    {{ period.company_periods_id }} - {{ period.company_periods_name }}: {{ DDMMYYYY(new Date(period.company_periods_start_date)) }} t/m {{ DDMMYYYY(new Date(period.company_periods_end_date)) }}
                  </option>
                </b-select>
              </b-field>
              
              <b-field v-if="frmData.settingsData" label="Previous settings file">
                <b-select placeholder="Select Settings" v-model="selectedSettingsFile" @input="selectSettingsChanged">
                  <option                
                    v-for="settingFile in frmData.settingsData.settingsFiles"
                    :value="settingFile"
                    :key="`${settingFile.path}/${settingFile.name}`">
                    {{ `${settingFile.path}/${settingFile.name}` }}
                  </option>
                </b-select>
              </b-field>
              
              <div class="columns is-multiline mt1em">            
                <div class="column is-4">
                  <b-field label="Roles" >
                    <div v-for="(role, index) in frmData.roles" v-bind:key="`role_${index}`">
                      {{ role.role_id }} - <strong>{{ role.name }}</strong>: {{ role.total }} users
                    </div>
                  </b-field>
                </div>
              
              
                <div class="column is-8">
                  <b-field label="CodeSets">
                    <div v-for="(period, index) in frmData.periodData" v-bind:key="`periodData_${index}`">
                      <div v-if="selectedPeriod.company_periods_id == period.company_periods_id">
                        
                        <div v-for="(codeSet, indexCodeSet) in period.codeSets" v-bind:key="`codeSets_${indexCodeSet}`">
                          <strong>{{ codeSet.codesets_schedule_period_name }}</strong>: {{ codeSet.codeSchedules.map(c => c.code_schedule_id).join(`, `) }}
                        </div>                    
                      </div>
                    </div>
                  </b-field>
                </div>
              </div>
              
              <div class="columns is-multiline mt1em"> 
                 <b-field label="Schedules">
                    <div v-for="(period, index) in frmData.periodData" v-bind:key="`periodData2_${index}`">
                      <div v-if="selectedPeriod.company_periods_id == period.company_periods_id">
                        
                        <div v-for="(schedule, indexSchedules) in period.schedules" v-bind:key="`codeSets_${indexSchedules}`">
                          {{ schedule.wa_schedules_id }}-<strong>{{ schedule.wa_schedules_name }}</strong>: {{ schedule.codeSchedules.map(c => c.wa_code_schedule_id).join(`, `)}}
                        </div>                    
                      </div>
                    </div>
                  </b-field>
              </div>
            
            </div>
            
            
            <div class="column is-6">              
              <b-field label="Settings">
                <b-input maxlength="2000000" type="textarea" custom-class="textareaHeightMainTemplate2" v-model="frmData.settingsContent" 
                @input="validateInputJSON"
                ></b-input>
              </b-field>
              
              <b-checkbox v-model="frmData.saveAsDefault">Save as default</b-checkbox>
              
              <b-field label="Schedule">
                <b-switch v-model="enableScheduledJSON">ScheduleJSON</b-switch>
                <b-datetimepicker
                    v-if="enableScheduledJSON"
                    v-model="scheduledDatetime"
                    rounded
                    placeholder="Click to select..."
                    icon="calendar-today"
                    :icon-right="scheduledDatetime ? 'close-circle' : ''"
                    :locale="locale"
                    :first-day-of-week="firstDayOfWeek"
                    :datepicker="{ showWeekNumber }"
                    :timepicker="{ enableSeconds, hourFormat }"
                    horizontal-time-picker>
                </b-datetimepicker>
              </b-field>
              
              <b-field v-if="enableScheduledJSON" label="InactivateSchedules:">
                <b-switch v-model="inActivateSchedules">InActivateSchedules:</b-switch>
                <MultiSelect v-if="inActivateSchedules" v-model="filterSchedules.selectValues" :label="getScheduleInactivateLabel()" @change="updateSelectedSchedules" />
              </b-field>
              
              <b-field label="RunPlan">
                <b-select v-model="selectedRunPlan" placeholder="Select run Plan">
                    <option
                        v-for="(runPlan, index) in frmData.runPlans"
                        :value="index"
                        :key="index">
                        {{ runPlan }}
                    </option>
                </b-select>
            </b-field>
              
            </div>
            
            
            
            
            
          </b-field>
          
          
          
          
          
          
          
        </section>
        
        
        <footer class="modal-card-foot">
          <!-- <b-button label="Close" @click="$parent.close()" /> -->
          <b-button label="Save" :type="this.inputJSONisValid ? 'is-primary' : 'is-warning is-light'" @click="save" />
          <div class="columns is-multiline">
            <div class="column is-12 hasError" v-for="(inputErr, key) in inputJSONErrMsg" v-bind:key="`${key}_json_errors`">
              {{ inputErr }}
            </div>
          </div>
        </footer>
      </div>
      
      <b-notification :closable="false">
        <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
      </b-notification>
    </section>
</template>

<script>

// import { mapState } from 'vuex';
import { DDMMYYYY } from '@/helpers/dates.js';
const jsonFormat = require("json-format")

import axios_api from '@/plugins/axios_api';
import { API_ROOT } from '@/config/app.js';
import MultiSelect from "@/components/public/general/multiSelect.vue";

export default {
    name: "jsonBuilderForm",
    
    components: {
      MultiSelect
    },
    
    props: {
      jsonBuildInfo: {
        title: '',
        note: '',
        periodData: [],
        settingsData: [],
        settingsContent: '',
        roles: []
      }
    },
    
    data() {
      return {
        pageLoading         : false,
        pageLoadingTimeout  : null,
        
        companyId           : null,
      
        frmData   : {
          title: '',
          note: '',
          settingsContent: '',
          saveAsDefault: true
        },
        selectedPeriod: 0,
        selectedSettingsFile: 0,
        
        inputJSONisValid: true,
        inputJSONErrMsg: [],
        
        enableScheduledJSON: false,
        scheduledDatetime: new Date(),
        showWeekNumber: false,
        enableSeconds: false,
        hourFormat: '24', // Browser locale
        locale: 'nl-NL', // Browser locale
        firstDayOfWeek: 1, // 0 - Sunday
        
        inActivateSchedules: false,
        inActivateScheduleList: [],
        
        filterSchedules         : {
          selectValues      : [],
          selectSet         : new Set([])
        },
        selectedRunPlan     : 0
      }
    },
    
    mounted(){
      console.info(`-->`, this.jsonBuildInfo)
      this.companyId = this.jsonBuildInfo.companyId
      this.frmData = this.jsonBuildInfo
      this.frmData.saveAsDefault = false
      this.selectedPeriod = this.frmData.periodData[0]
      this.selectedSettingsFile = this.frmData.settingsData.settingsFiles[0]
      this.updateFilterSchedulesValues()
      this.updateSettings()
      
      this.scheduledDatetime = new Date()
      this.scheduledDatetime.setDate(this.scheduledDatetime.getDate() + 1)
      this.scheduledDatetime.setHours(0)
      this.scheduledDatetime.setMinutes(1)
      this.scheduledDatetime.setSeconds(0)
      // console.info(`frmData.runPlans:`, this.frmData)
    },
    
    methods: { 
      DDMMYYYY,
      updateFilterSchedulesValues(){
        let schedules = []
        for(let period of this.frmData.periodData) {
          if (this.selectedPeriod.company_periods_id !== period.company_periods_id) continue
          for(let schedule of period.schedules) {
            schedules.push({
                id: schedule.wa_schedules_id,
                name: schedule.wa_schedules_name,
                label: `${schedule.wa_schedules_id}-${schedule.wa_schedules_name}`,
                value: false,
            })
          }
        }
        schedules = schedules.sort((a,b) => { 
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1
        if (a.label.toLowerCase() < b.label.toLowerCase()) return 1
        return 0
      })
        
        this.filterSchedules.selectValues = schedules
        this.filterSchedules.selectSet = this.getScheduleSelectedItems(this.filterSchedules.selectValues)      
      },
      getScheduleSelectedItems(scheduleValues){
        let list = new Set([])
        for(let schedule of scheduleValues) {
          if (schedule.value !== true) continue
          // console.info(`-->>>codeSchedule:`, codeSchedule)
          list.add(schedule.id)        
        }
        // console.info(`getCodeScheduleSelectedItems:`, [...list].join(`, `))
        return new Set([...list])
      },
      selectPeriodChanged(newPeriod){
        console.info(`new Period selected:`, newPeriod)
        this.selectedPeriod = newPeriod
        this.updateSettings()
      },
      validateInputJSON() {
        console.info(`validateInputJSON:`)
        let jsonContent = this.frmData.settingsContent
        let inputJSONisValid = true
        let inputJSONWarnings = []
        try {
          let mustHaveKeys = ['companyId', 'companyPeriodId', 'scheduleIdList', 'codePairs', 'maxHoursMultiplier', 'maxPerMonthWeek', 'maxHolidays', 'specific_role_shifts', 'generateNightStreaks', 'generateWeekendStreaks', 'filters']
          if (!jsonContent || jsonContent.trim() === '') {
            inputJSONWarnings.push('no input json found')
            throw `no input json found`
          }
          
          let inputJSON = JSON.parse(jsonContent)
          for(let key of mustHaveKeys) {
            // console.info(`key: -${key}:`, inputJSON[key])
            // console.info(typeof(inputJSON[key]))
            if (typeof(inputJSON[key]) === "undefined") {
              console.info(`json key ${key} is missing`)
              inputJSONWarnings.push(`json key ${key} is missing`)
              inputJSONisValid = false
            }
            console.info(`-----`)
          }
          
          
          if (typeof(inputJSON.maxHoursMultiplier) === "undefined") {       
            console.info(`ok`)     
          } else if (isNaN(inputJSON.maxHoursMultiplier)) {
            inputJSONWarnings.push(`maxHoursMultiplier invalid`)
            inputJSONisValid = false
          } else if (inputJSON.maxHoursMultiplier > 1) {
            inputJSONWarnings.push(`maxHoursMultiplier > 1`)
            inputJSONisValid = false
          } else if (inputJSON.maxHoursMultiplier < 0.8) {
            inputJSONWarnings.push(`maxHoursMultiplier < 0.8`)
            inputJSONisValid = false
          }
          
          if (typeof(inputJSON.maxHolidays) === "undefined") {            
            console.info(`ok`)
          } else if (isNaN(inputJSON.maxHolidays)) {
            inputJSONWarnings.push(`maxHolidays invalid`)
            inputJSONisValid = false
          } else if (inputJSON.maxHolidays > 10) {
            inputJSONWarnings.push(`maxHolidays > 10`)
            inputJSONisValid = false
          } else if (inputJSON.maxHolidays < 0) {
            inputJSONWarnings.push(`maxHolidays < 0`)
            inputJSONisValid = false
          }
          
          if (typeof(inputJSON.scheduleIdList) === "undefined" || inputJSON.scheduleIdList.constructor != Array || inputJSON.scheduleIdList.length <= 0) {
            inputJSONWarnings.push(`no schedules found`)
            inputJSONisValid = false
          }
          
          if (typeof(inputJSON.filters) === "undefined" || (inputJSON.filters.constructor != Array)) {
            inputJSONWarnings.push(`no filters found`)
            inputJSONisValid = false
          }
          
          let allowedRoles = new Set(this.frmData.roles.map(r => r.name))
          let allowedSchedules = new Set([])
          let allowedCodeSchedules = new Set([])
          for(let period of this.frmData.periodData) {
            if (parseInt(inputJSON.companyPeriodId) !== period.company_periods_id) continue
            for(let schedule of period.schedules) {
              allowedSchedules.add(schedule.wa_schedules_id)
              for(let codeSchedule of schedule.codeSchedules) {
                allowedCodeSchedules.add(codeSchedule.wa_code_schedule_id)
              }
            }
          }
          
          for(let schedule of inputJSON.scheduleIdList) {
            if (allowedSchedules.has(schedule) !== true) {
              inputJSONWarnings.push(`Schedule ${schedule} not found in period ${inputJSON.companyPeriodId}`)
              inputJSONisValid = false
            }
          }
          
          for(let specific_role in inputJSON.specific_role_shifts) {
            if (allowedRoles.has(specific_role) !== true) {
              inputJSONWarnings.push(`Specific role ${specific_role} not found`)
              inputJSONisValid = false
            }
            const codeSchedules = inputJSON.specific_role_shifts[specific_role]
            for(let codeSchedule of codeSchedules) {
              if (allowedCodeSchedules.has(codeSchedule) !== true) {
                inputJSONWarnings.push(`Specific role ${specific_role} - codeSchedule ${codeSchedule} not found`)
                inputJSONisValid = false
              }
            }
          }
          
          
          
        
        } catch (err) {
          console.info(`Error validateInputJSON:`, err.message); 
          inputJSONWarnings.push(err.message)
          inputJSONisValid = false          
        }
        this.inputJSONisValid = inputJSONisValid
        this.inputJSONErrMsg = inputJSONWarnings
      },
      selectSettingsChanged(selectedSettingsFile){
        console.info(`new SettingsFile selected:`, selectedSettingsFile)
        this.selectedSettingsFile = selectedSettingsFile
        this.updateSettings()
      },
      
      updateSettings() {
        console.info(`this.selectedSettingsFile:`, this.selectedSettingsFile)
        let settings = JSON.parse(this.selectedSettingsFile.content)
        
        settings.companyId = this.selectedPeriod.company_periods_company_id
        settings.companyPeriodId = this.selectedPeriod.company_periods_id
        
        let scheduleList = this.selectedPeriod.schedules.filter(schedule => { 
            return schedule.wa_schedules_is_active === 1 && 
                    schedule.wa_schedules_is_archived === 0 && 
                    schedule.wa_schedules_deleted_at === null
        })
        const curSchedules = scheduleList.map(schedule => schedule.wa_schedules_id)
        if (curSchedules.length > 0) {
          settings.scheduleIdList = scheduleList.map(schedule => schedule.wa_schedules_id)
        }
        
        // this.frmData.settingsContent = new Date().toISOString() + jsonFormat(settings)
        this.frmData.settingsContent = jsonFormat(settings)
        this.validateInputJSON()
      },
      
      
      async ShowPageLoading(maxDuration = 30000){
        this.pageLoading = true;
        
        if (this.pageLoadingTimeout !== null) {
          clearTimeout(this.pageLoadingTimeout);
        }
        
        this.pageLoadingTimeout = setTimeout(() => {
          this.HidePageLoading();
        }, maxDuration);
      },
      
      async HidePageLoading() {
        this.pageLoading = false;
        if (this.pageLoadingTimeout !== null) {
          clearTimeout(this.pageLoadingTimeout);
        }
      },
      
      async save(){
        if (!this.inActivateSchedules) return this.saveConfirm()
        
        if (this.inActivateSchedules && this.filterSchedules.selectSet.size === 0 ) {
          return this.$buefy.dialog.alert({ title: 'Error', message: 'No schedules selected to Inactivate!', type: 'is-danger' })
        }
        
        const thaThis = this
        this.$store.commit("dialog/setDialog", 
            { DialogType: 'confirm', 
              title: `Inactivate schedules`, 
              message: `Are you sure you want to inactivate schedules: ${ [...this.filterSchedules.selectSet].join(`, `)}`, 
              confirmText: `yes`, 
              onConfirm :() => { thaThis.saveConfirm( )}, 
            }
          )
      },
      async saveConfirm(){
        // console.info(`this.frmData:`, this.frmData)
        if (this.frmData.title.trim() === '') {
          return this.$buefy.dialog.alert({ title: 'Error', message: 'invalid title', type: 'is-danger' })
        }
        
        const curDate = new Date()
        if (this.enableScheduledJSON && this.scheduledDatetime.getTime() - 60*1000 <= curDate.getTime()) {
          return this.$buefy.dialog.alert({ title: 'Error', message: 'ScheduleTime is to short from now', type: 'is-danger' })
        }
        
        let settings = ''
        try {
          if (this.frmData.settingsContent.trim() === '') throw new Error('invalid settings')
          
          console.info(`this.frmData.settingsContent:`, this.frmData.settingsContent)
          settings = JSON.parse(this.frmData.settingsContent.trim())
          if (settings.companyId !== this.companyId) throw new Error(`invalid companyId: ${settings.companyId} is not valid`)
          
          let periodId = parseInt(settings.companyPeriodId)
          if (isNaN(periodId) || periodId <=0) throw new Error(`invalid companyPeriodId: ${settings.companyPeriodId} is not valid`)
          settings.companyPeriodId = periodId
          
          if (!settings.scheduleIdList || settings.scheduleIdList.length<=0) throw new Error(`invalid scheduleIdList: ${settings.scheduleIdList.join(`,`)} is not valid`)
        
        } catch (err) {
          if (err.message) {
            return this.$buefy.dialog.alert({ title: 'Error', message: `invalid settings JSON: ${err.message}`, type: 'is-danger' })
          }
          return this.$buefy.dialog.alert({ title: 'Error', message: `invalid settings JSON`, type: 'is-danger' })
        }
        
        this.ShowPageLoading(1500)
        const save = {
          title: this.frmData.title,
          note: this.frmData.note,
          settings: settings,
          scheduleJSON: this.enableScheduledJSON ? 1 : 0,
          scheduleJSONDateTime: this.enableScheduledJSON ? this.scheduledDatetime.getTime() : 0,
          inActivateSchedules: this.inActivateSchedules ? 1 : 0,
          inActivateScheduleList: this.inActivateSchedules ? [...this.filterSchedules.selectSet] : [],
          runPlan: this.frmData.runPlans[this.selectedRunPlan],
          saveAsDefault: this.frmData.saveAsDefault
        }
        console.info(`--->>>save:`, save)
        
        let response = await axios_api.post(`${API_ROOT}/maximus/company/${this.companyId}/buildJSON/new`, save);
        if (response.data.result !== 1) {
          this.$buefy.dialog.alert({ title: 'Error', message: 'unable to save json', type: 'is-danger' })
          this.HidePageLoading()
          return;
        }
        this.HidePageLoading()
        
        
        this.$buefy.dialog.alert({ title: 'json build started', message: 'build will be completed soon', type: 'is-info' })
        
        // this.$emit('updateData')
        
        this.$parent.close()        
      },
      
      // clearDateTime () {
      //   this.selected = null
      // },
      
      getArrayTrueValueSet(obj) {
        let list = new Set([])
        for(let item of obj) {
          if (item.value !== true) continue
          list.add(item.label.toLowerCase())
        }
        // console.info(`getArrayTrueValueSet:`, list)
        return list
      },
      getScheduleInactivateLabel(){
        if (this.filterSchedules.selectSet.size === 0) return 'Select Schedules'
        return 'Schedu:' + [...this.filterSchedules.selectSet].join(`, `)
      },  
      updateSelectedSchedules(){
        this.filterSchedules.selectSet = this.getScheduleSelectedItems(this.filterSchedules.selectValues)
      },
            
    }
};
</script>

<style>
  .highlight{
    color: #0000cc;
    text-decoration: underline;
  }
  
  .textareaHeightMainTemplate2{
    /* background-color: red; */
    font-size: 16px;
    height: 700px;
    max-height: 700px!important;
  }
  
  .hasError{
    color: red;
    font-weight: 12px;
  }
    
</style>
